import { cloneDeep } from '@apollo/client/utilities';
import { createStore } from 'vuex';
import { api, DailyLimitMetError } from '../api/api';
import { eLearningExtendedSubscription, notificationsUpdatedSubscription } from '../api/schedulerApi';
import { GUID, Timestamp } from '../common-types/CommonTypes';
import { auth, State as AuthState } from './modules/auth';
import { instructor, State as InstructorState } from './modules/instructor';
import { theme, State as ThemeState } from './modules/theme';
import { sync, State as SyncState } from './modules/sync';
import { toast } from '../toast/Toast';
import { parseISO, startOfToday, addDays, differenceInCalendarDays, } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import envConfig from "@/core/config/env.config";
import { getPlatforms } from "@ionic/vue";
import  platform  from "platform";
import { Network } from '@capacitor/network';
import { Preferences } from '@capacitor/preferences';

//import LogRocket from 'logrocket';
import Hotjar from '@hotjar/browser';
import { durationFormatterEn, formatEarliestDriversTestDate } from '../util/helpers';
import { faro } from '@grafana/faro-web-sdk';

const timeRequiredInMinutes = 45;

export type StateSetting = {
	schedulerSettings: {
		observationDisabled: boolean;
		licenseEligibility: {
			years: number;
			months: number;
		}
		permitEligibility: {
			years: number;
			months: number;
		}
	},
	state: string;
}

export interface State {
	studentsClassroomProgress: StudentsClassroomUserProgress[],
	dailyELearningLimits: Map<string, {formatted: string, dailyELearningLimit: number}>;
	hideTabFooter: boolean;
	timeSlotsData: TimeSlot[],
	userAppointmentsData: TimeSlot[],
	timeSlotVisibility: string;
	initialized: boolean;
	networkError: boolean;
	authError: boolean;
	connectionStatus: string;
	notifications: Notification[];
	blockingSchedulerInfo: BlockingSchedulerInfo;
	selectedUser: GUID | string
	selectedInstructorDrivingIDs: InstructorDrivingIDs;
	validSchedulerUser: boolean;
	selectedUserClassroomStudentCourseID: string | null;
	selectedUserDrivingStudentCourseID: string | null;
	attemptedRoute: any;
	recalculateBalance: boolean;
	showHelpModal: boolean;
	showConnectionModal: boolean;
	showSettingsModal: boolean;
	showSettingsData: object;
	institutionSettings: InstitutionSettings;
	stateSettings: StateSettings[];
	featureFlags: FeatureFlags;
	browserSupport: BrowserSupport;
	students: User[];
	onHoldMessage: string;
	btwCourseOnHold: boolean;
	eLearningCourseOnHold: boolean;
	hasBTWCourse: boolean;
	observeDisabled: boolean;
	haseLearningCourse: boolean;
	hasCourse: boolean;
	courseIsActive: boolean;
	courseComplete: boolean;
	balanceDueMap: object;
	maxObservesReached: boolean;
	maxDrivesReached: boolean;
	selectedSessions: SelectedSessions | null;
}

export interface SelectedSessions {
	[key: string]: {
		sessionEnd: string;
		sessionType: 'drive' | 'observe';
	};
}

export interface InstitutionSettings {
	email: string;
	moduleSettings: ModuleSettings;
	instructorAppSettings: InstructorAppSettings;
	schedulerSettings: SchedulerSettings;
	paymentSettings: PaymentSettings;
	registrationInstitutionConfig: RegistrationInstitutionConfig;
	slug: string;
	name: string;
	timeZone: string;
	notifications: Notification[];
	phone: string;
	stateSetting: StateSetting;
	trial: Boolean;
	requiredDriveOrObserveHoursFeature?: boolean;
}

export interface StateSettings {
	state: string;
	schedulerSettings: StateSchedulerSettings;
}

export interface StateSchedulerSettings {
	observationDisabled: boolean;
	licenseEligibility: StateLicenseEligibility;
	permitEligibility: StatePermitEligibility;
}

export interface StateLicenseEligibility {
	years: number;
	months: number;
}

export interface StatePermitEligibility {
	years: number;
	months: number;
}

export interface Notification {
	alert: boolean;
	endDate: number;
	message: string;
	startDate: number;
	title: string;
	type: string;
}

export interface BlockingSchedulerInfo {
	formattedMessage: string;
	minimumPaymentAmount: number;
	behindTheWheelStudentCourseTotals: TotalsPerStudentCourse;
}

export interface TotalsPerStudentCourse {
	studentCourseID: string;
	balance: number;
	fees: number;
	payments: number;
}

export interface FeatureFlags {
	inAppBilling: boolean;
	appVersions: AppVersions;
	logRocketSettings: LogRocketSettings;
}

export interface BrowserSupport {
	minimumSupportedMethods: JavaScriptMethod[];
}

export type JavaScriptMethod = {
	constructorType: 'Object' | 'Array' | 'Boolean' | 'Number' | 'Symbol' | 'BigInt' | 'String';
	name: string;
}

export type AppVersions = {
	android: Versions;
	iOS: Versions;
}

export type Versions = {
	current: string;
	min: string;
}

export type LogRocketSettings = {
	maximumLiteLesson: Number;
}

export interface ModuleSettings {
	schedulerAccess: boolean;
	eLearning: boolean;
	registration: boolean;
	instructorApp: boolean;
	customBranding: boolean;
}

export interface SchedulerSettings {
	cancellationFee: number;
	mandatoryGap: number;
	lockFutureAppointments: LockFutureAppointments;
	schedulingOverrides: SchedulingOverrides;
	calculateMandatoryGap: boolean;
	calculateMandatoryGapOffsetDays: number;
	minimumCancellationTime: string;
	hideInstructors: boolean;
}

export interface InstructorAppSettings {
	showHighSchoolOnSessionCard: Boolean;
}

export interface LockFutureAppointments {
	ifBalanceDue: boolean;
	maxDrivesAllowedWhenBalanceDue: number;
	ifAwaitingLearningCompletion: boolean;
	maxDrivesAllowedBeforeCompletion: number;
	ifAwaitingRoadTestScheduling: boolean;
	maxDrivesAllowedBeforeScheduling: number;
}

export interface SchedulingOverrides {
	enabled: boolean;
	maxDrivesAllowed: number;
	maxObservesAllowed: number;
}

export interface PaymentSettings {
	percent: number;
	enabled: boolean;
	minAmount: number;
}

export interface RegistrationInstitutionConfig {
	payment: Payment;
};

export interface Payment {
	mode: String
	gateway: String
	testApiKey: String
	payTrakSDKVersion: String
	liveApiKey: String
	locationID: String
	allowCreditCardPayment: Boolean
}

export type StoreState = State & AuthState & ThemeState & SyncState;

export type StudentsClassroomUserProgress = {
	userID: GUID;
	classroomUserProgress: ClassroomUserProgress;
}

export type ClassroomUserProgress = {
	syllabus: Syllabus;
	lessons: [LessonProgress];
	completedDate: number;
	timeSpent: [DailyLearningTime];
	lockDate: Timestamp;
}

export type LessonProgress = {
	id: String
	lessonID: String
	completeDate: Timestamp
	progressItems: [ProgressItem]
}

export type ProgressItem = {
	type: String,
	id: String
	reference: String
	completedDate: Timestamp
	details: [QuestionAnswer]
	score: Number
}

export type QuestionAnswer = {
	id: String
	question: Question
	chosenAnswerID: String
}

export type DailyLearningTime = {
	date: string;
	timeSpent: number;
}

export type Syllabus = {
	name: string;
	id: GUID;
	institution: string;
	version: number;
	quizSettings: QuizOrTestSettings;
	testSettings: QuizOrTestSettings;
	curriculums: [Curriculum];
	duration: number;
	lessons: [Lesson];
}

export type Curriculum = {
	name: string;
	id: GUID;
	version: number;
	quizSettings: QuizOrTestSettings;
	testSettings: QuizOrTestSettings;
	duration: number;
	lessons: [Lesson];
}

export type QuizOrTestSettings = {
	numberOfQuestions: number;
	attemptsAllowed: AttemptsAllowed;
	showCorrectAnswers: boolean;
	showCorrectIncorrectQuestions: boolean;
	requiredPassingScore: number;
}

export type AttemptsAllowed = number;

export type Lesson = {
	name: string;
	lessonID: string;
	topics: [Topic];
	test: [Question];
}

export type Topic = {
	name: string;
	topicID: string;
	estimatedMinutes: number;
	resources: [Resource];
	quiz: [Question];
}

export type Question = {
	questionID: string;
	questionText: string;
	questionImage: string | null;
	answers: [Answer];
}

export type Answer = {
	answerID: string;
	text: string;
	correct: boolean;
}

export type Resource = {
	resourceID: string;
	content: ResourceContent;
}

export type ResourceContent = TextResourceContent | ImageResourceContent | VideoResourceContent | BreakResourceContent

export type TextResourceContent = {
	type: string;
	text: string;
}

export type ImageResourceContent = {
	type: string;
	image: string;
}

export type VideoResourceContent = {
	type: string;
	video: string;
}

export type BreakResourceContent = {
	type: string;
	text: string;
}

export type TimeSlot = {
	institution: string;
	location: Location;
	instructor: ContactInfo;
	vehicle: GUID;
	zones: [Zone];
	schools: [School];
	sessionDefinitions: [SessionDefinition];
	timeSlotID: GUID;
	recurringFrequency: RecurringFrequency;
	label: string;
	instances: [TimeSlotInstance];
}

export type TimeSlotVM = {
	institution: string;
	location: Location;
	instructor: ContactInfo;
	vehicle: GUID;
	zones: [Zone];
	schools: [School];
	sessionDefinitions: [SessionDefinition];
	timeSlotID: GUID;
	recurringFrequency: RecurringFrequency;
	label: string;
	instance: TimeSlotInstance;
	scheduledSessions: SessionDetails[];

	isOpen: boolean;
	isPast: boolean;
	hasOpenDriveSessions: boolean;
	hasOpenObserveSessions: boolean;
	isScheduled: boolean;
	hasStudents: boolean;
	hasInstructor: (instructor: User) => boolean;
	isAtLocation: (location: Location) => boolean;
	isForSchool: (school: School) => boolean;
	isForZones: (zones: Zone[]) => boolean;
	matchesSchoolOrZone: (school: School, zones: Zone[]) => boolean;
}

export type TimeSlotInstance = {
	date: string;
	sessions: SessionDetails[];
	note: string;
	status: string;
	groupedSessions: SessionGroup[];
}

export type SessionGroup = {
	sessions: SessionDetails[];
}

export type InstructorTimeSlotData = {
	location: Location;
	vehicle: Vehicle;
	instructor: ContactInfo;
	instanceID: GUID;
	date: string;
	note: string;
}

export type InstructorDrivingIDs = {
	timeSlotID: GUID;
	instanceID: GUID;
	sessionID: GUID;
};

export type Vehicle = {
	vehicleID: GUID;
	name: string;
	year: string;
	make: string;
	model: string;
	license: string;
	VIN: string;
	seats: number;
}

export type SessionDetails = {
	sessionID: GUID;
	sessionType: string;
	sessionStart: string;
	sessionEnd: string;
	userID: GUID;
	studentCourseID: GUID;
	note: string;
	status: string;
	customPickupLocation?: Location
}

export type Location = {
	locationID: GUID;
	name: string;
	address: string;
	city: string;
	state: string;
	zip: string;
}

export type RecurringFrequency = {
	frequency: string;
	interval: number;
	byWeekDay: [string];
	singleDates: [number];
	dateStart: number;
	until: number;
	excludeDates: [number];
}

export type SessionDefinition = {
	sessionStartTime: string;
	sessionEndTime: string;
	observe: number;
	drive: number;
}

export type School = {
	name: string;
	locationID: string;
}

export type Zone = {
	name: string;
	id: string;
}

export type ContactInfo = {
	userID: GUID;
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	smsCompatible: boolean;
}

export type Role = {
	slug: string;
	name: string;
}

export type CourseCategory = {
	slug: string;
}

export type CourseInstance = {
	start: Timestamp,
	end: Timestamp
}

export type Course = {
	categories: CourseCategory[];
	courseStatus: string;
	instances: CourseInstance[];
	eLearningDailyLimitMinutes: number;
	eLearningMinDaysBeforeFinishing: number;
	requiredDriveHours?: number;
	allowDriveOverage?: boolean;
	requiredObserveHours?: number;
	allowObserveOverage?: boolean;
}

export type StudentCourse = {
	onHold: boolean;
	course: Course;
	studentCourseID: GUID;
	studentCourseStatus: string;
	dates: StudentCourseDates;
	futureDrivingData: FutureDrivingData;
	driveHoursCompleted?: number;
	driveHoursScheduled?: number;
	requiredDriveHours?: number;
	requiredObserveHours?: number;
	observationHoursCompleted?: number;
	observationHoursScheduled?: number;
	earliestDriversTestDate?: string;
}

export type StudentCourseDates = {
	eLearningStart: string;
	eLearningActivation: string;
	eLearningCompletion: string;
	classCompletion: string;
	roadTestScheduled: string;
}

export type FutureDrivingData = {
	mandatoryGapSpans: MandatoryGapSpan[]
}

export type MandatoryGapSpan = {
	start: string,
	end: string
}

export type User = {
	courses: StudentCourse[];
	birthDate: string;
	correctiveLensesRequired: boolean
	firstName: string;
	lastName: string;
	permitIssued: number;
	permitIssuingState: string;
	permitNumber: string;
	roles: Role[];
	students: User[];
	userID: string;
	email: string;
	institutions: string[];
	zones: Zone[];
	school: School;
	driveHours?: number;
	driveHoursScheduled?: number;
	requiredDriveHours?: number;
	allowDriveOverage?: boolean;
	requiredObserveHours?: number;
	allowObserveOverage?: boolean;
	observeHours?: number;
	observeHoursScheduled?: number;
	earliestDriversTestDate?: string;

	isDev: boolean;
	isOwner: boolean;
	isInstructor: boolean;
	isStudent: boolean;
	isParent: boolean;
	isAdmin: boolean;
}

const store = createStore<State>({
	modules: {
		auth: auth as any,
		instructor: instructor as any,
		theme: theme as any,
		sync: sync as any
	},
	state() {
		return {
			authError: false,
			connectionStatus: '',
			btwCourseOnHold: false,
			eLearningCourseOnHold: false,
			hasBTWCourse: false,
			observeDisabled: false,
			haseLearningCourse: false,
			hasCourse: false,
			courseIsActive: false,
			courseComplete: true,
			balanceDueMap: {},
			initialized: false,
			timeSlotsData: [],
			userAppointmentsData: [],
			selectedInstructorDrivingIDs: null,
			timeSlotVisibility: '',
			networkError: false,
			validSchedulerUser: false,
			featureFlags: {
				inAppBilling: false,
				appVersions: {
					android: {
						current: '',
						min: ''
					},
					iOS: {
						current: '',
						min: ''
					}
				},
				logRocketSettings: {
					maximumLiteLesson: 1
				}
			},
			browserSupport: {
				minimumSupportedMethods: []
			},
			institutionSettings: {
				email: '',
				moduleSettings: {
					schedulerAccess: false,
					eLearning: false,
					registration: false,
					instructorApp: false
				},
				instructorAppSettings: null,
				schedulerSettings: {
					cancellationFee: 0,
					mandatoryGap: 0,
					calculateMandatoryGap: false,
					calculateMandatoryGapOffsetDays: 0,
					minimumCancellationTime: '',
					hideInstructors: false,
					lockFutureAppointments: null,
					schedulingOverrides: null
				},
				slug: '',
				phone: '',
				logo: '',
				name: '',
				stateSetting: {
					schedulerSettings: {
						observationDisabled: false,
						licenseEligibility: {
							years: 16,
							months: 0
						},
						permitEligibility: {
							years: 15,
							months: 0
						}
					},
					state: ''
				},
				trial: false,
				paymentSettings: null,
				registrationInstitutionConfig: null,
				timeZone: 'America/Chicago',
				notifications: []
			},
			stateSettings: [],
			onHoldMessage: '',
			hideTabFooter: false,
			recalculateBalance: false,
			selectedUser: '',
			selectedStudent: {},
			selectedUserClassroomStudentCourseID: null,
			selectedUserDrivingStudentCourseID: null,
			showHelpModal: false,
			showConnectionModal: false,
			showSettingsModal: false,
			showSettingsData: null,
			attemptedRoute: '',
			// TODO: set this from the institution data
			stateList: [
				{ "name": "Illinois", "abbreviation": "IL" },
				{ "name": "Indiana", "abbreviation": "IN" },
				{ "name": "Michigan", "abbreviation": "MI" },
				{ "name": "Minnesota", "abbreviation": "MN" },
				{ "name": "Wisconsin", "abbreviation": "WI" }
			],
			students: [],
			studentsClassroomProgress: [],
			dailyELearningLimits: new Map(),
			notifications: [],
			blockingSchedulerInfo: {
				formattedMessage: '',
				minimumPaymentAmount: 0,
				behindTheWheelStudentCourseTotals: null,
			},
		};
	},
	getters: {
		connectionStatus(state) {
			return state.connectionStatus;
		},
		appPlatform() {
			if (envConfig.platform === 'ios-ionic') {
				return 'ios';
			} else if (envConfig.platform === 'android-ionic') {
				return 'android';
			}
			return envConfig.platform;
		},
		appVersion() {
			return envConfig.version;
		},
		appUpdateURL(state, getters) {
			if (getters.appPlatform === 'android') {
				return 'https://play.google.com/store/apps/details?id=ckh.drivetrak';
			} else if (getters.appPlatform === 'ios') {
				return 'https://apps.apple.com/us/app/drivetrak/id1587008563';
			}
			return '';
		},
		networkError(state) {
			return state.networkError;
		},
		authError(state) {
			return state.authError;
		},
		completedAllButLastLesson(state, getters) {
			if (!getters.selectedClassroomProgress) { return false; }
			const classroomUserProgress = getters.selectedClassroomProgress;

			let completedLessons = classroomUserProgress?.lessons.filter(cup => cup.completeDate) || [];
			if (completedLessons?.length && completedLessons.length === (classroomUserProgress.syllabus.lessons.length - 1)) {
				return true;
			} else {
				return false;
			}
		},
		customBrandingClass(state, getters) {

			if (getters.hasCustomDomain) {
				return state.institutionSettings.slug;
			}

			if (
				!state.institutionSettings?.slug
				|| (!state.institutionSettings?.moduleSettings?.customBranding)
				|| (!getters.user.isStudent && !getters.user.isParent)
			) { return 'drivetrak'; }

			// If custom branding is enabled.
			if (state.institutionSettings.moduleSettings.customBranding) {
				return state.institutionSettings.slug;
			}

			return 'drivetrak';
		},
		// Checks if the daily elapsed time plus the estimated minutes from the first incomplete topic under `lessonID` would be over the daily limit
		dailyELearningLimitMet:(state, getters) => {
			const today = formatInTimeZone(new Date, getters.institutionSettings.timeZone, 'yyyy-MM-dd');

			return state.dailyELearningLimits.get(today);
		},
		eLearningInPersonCourseDateLock(state, getters) {
			if(!getters.user.isStudent || !getters.haseLearningCourse || !getters.selectedUserClassroomStudentCourseID) return true;
			const student = state.students.find(s => s.userID === getters.selectedUser);

			if (!student) return false
			const selectedUserClassroomStudentCourseID = cloneDeep(getters.selectedUserClassroomStudentCourseID);
			const selectedUserClassroomStudentCourseIDSplit = selectedUserClassroomStudentCourseID.split('::');
			const studentCourseID = selectedUserClassroomStudentCourseIDSplit[2];
			const studentCourse = student.courses.find(c => c.studentCourseID === studentCourseID);

			const today = formatInTimeZone(new Date(), getters.institutionSettings.timeZone, 'yyyy-MM-dd');

			return studentCourse.course.instances?.some((instance) => today === formatInTimeZone(new Date(instance.start), getters.institutionSettings.timeZone, 'yyyy-MM-dd')) || false;
		},
		futureAppointmentsLocked(state, getters) {
			const {
				ifBalanceDue: lockFutureAppointmentsifBalanceDue = false,
				maxDrivesAllowedWhenBalanceDue = 0,
				ifAwaitingLearningCompletion: lockFutureAppointmentsifAwaitingLearningCompletion = false,
				maxDrivesAllowedBeforeCompletion = 0,
				ifAwaitingRoadTestScheduling: lockFutureAppointmentsifAwaitingRoadTestScheduling = false,
				maxDrivesAllowedBeforeRoadTestScheduling = 0,
				ifMaxScheduledAppointments: lockFutureAppointmentsifMaxScheduledAppointments = false,
				maxScheduledAppointments = 0
			} = getters?.institutionSettings?.schedulerSettings?.lockFutureAppointments ?? {};
			const {
				enabled: schedulerOverrideEnabled = false,
				maxDrivesAllowed: schedulerOverrideMaxDrivesAllowed = 0,
				maxObservesAllowed: schedulerOverrideMaxObservesAllowed = 0
			} = getters?.institutionSettings?.schedulerSettings?.schedulingOverrides ?? {};

			if (!lockFutureAppointmentsifAwaitingRoadTestScheduling && !lockFutureAppointmentsifBalanceDue && !lockFutureAppointmentsifAwaitingLearningCompletion && !schedulerOverrideEnabled) {
				return {
					locked: false
				}
			}

			let lockedFromBalance = false;
			let lockedFromLearningCompletion = false;
			let lockedFromAwaitingRoadTestScheduling = false;
			let lockedFromMaxSessions = {
				drives: false,
				observes: false
			};
			let lockedFromSingleSchedule = false;

			const {driveSessions, observeSessions} = state.userAppointmentsData.flatMap((timeSlot) =>
				timeSlot.instances.flatMap((instance) =>
					instance.sessions.filter((s) =>
						s.userID === state.selectedUser
						&& ['SCHEDULED', 'COMPLETED'].includes(s.status)
					)
				)
			).reduce((agg, curr) => {
				if(curr.sessionType === 'drive') {
					agg.driveSessions.push(curr);
				}
				if(curr.sessionType === 'observe') {
					agg.observeSessions.push(curr);
				}
				return agg;
			}, {
				driveSessions: [],
				observeSessions: []
			});

			const numScheduledAppointments = state.userAppointmentsData.flatMap((timeSlot) =>
				timeSlot.instances.filter((instance) =>
					instance.sessions.some((s) =>
						s.userID === state.selectedUser
						&& ['SCHEDULED'].includes(s.status)
					)
				)
			).length;

			const studentCourse = getters.selectedStudent.courses.find(c => c.studentCourseID === getters.selectedUserDrivingStudentCourseID);
			const maxDrivesAllowed = schedulerOverrideEnabled ? schedulerOverrideMaxDrivesAllowed : studentCourse.course.requiredDriveHours ?? 0;
			const maxObservesAllowed = schedulerOverrideEnabled ? schedulerOverrideMaxObservesAllowed : studentCourse.course.requiredObserveHours ?? 0;

			const numDriveSessions = (driveSessions.length + (studentCourse.driveHoursOffset ?? 0));
			const numObserveSessions = (observeSessions.length + (studentCourse.observationHoursOffset ?? 0));

			if (!state.institutionSettings?.requiredDriveOrObserveHoursFeature) {
				if (maxDrivesAllowed <= numDriveSessions) {
					lockedFromMaxSessions.drives = true;
				}
				if (maxObservesAllowed <= numObserveSessions) {
					lockedFromMaxSessions.observes = true;
				}
			} else {
				if (getters.maxObservesReached) {
					lockedFromMaxSessions.observes = true;
				}
				if (getters.maxDrivesReached) {
					lockedFromMaxSessions.drives = true;
				}
			}

			if (lockFutureAppointmentsifMaxScheduledAppointments ) {
				if (numScheduledAppointments >= maxScheduledAppointments) {
					lockedFromSingleSchedule = true;
				}
			}

			if (
				lockFutureAppointmentsifBalanceDue
				&& maxDrivesAllowedWhenBalanceDue <= numDriveSessions
				&& getters.hasBalanceDue
			) {
				lockedFromBalance = true;
			}

			if (
				lockFutureAppointmentsifAwaitingRoadTestScheduling
				&& maxDrivesAllowedBeforeRoadTestScheduling <= numDriveSessions
				&& !studentCourse.dates?.roadTestScheduled
			) {
				lockedFromAwaitingRoadTestScheduling = true;
			}

			if (lockFutureAppointmentsifAwaitingLearningCompletion) {
				const isClassroomCourse = studentCourse.course.categories.some(({slug}) => slug === 'classroom');
				const hasClassroomCompletedDate = !!studentCourse.dates.classCompletion;

				const isELearningCourse = studentCourse.course.categories.some(({slug}) => slug === 'e-learning');
				const hasELearningCompletedDate = !!studentCourse.dates.eLearningCompletion;

				if (
					(isClassroomCourse && !hasClassroomCompletedDate)
					|| (isELearningCourse && !hasELearningCompletedDate)
				) {
					if (maxDrivesAllowedBeforeCompletion <= numDriveSessions) {
						lockedFromLearningCompletion = true;
					}
				}
			}

			return {
				locked: lockedFromBalance || lockedFromLearningCompletion || lockedFromAwaitingRoadTestScheduling || lockedFromSingleSchedule,
				lockedDrives: lockedFromMaxSessions.drives,
				lockedObserves: lockedFromMaxSessions.observes,
				balance: lockedFromBalance,
				learning: lockedFromLearningCompletion,
				roadTest: lockedFromAwaitingRoadTestScheduling,
				maxDrives: lockedFromMaxSessions.drives,
				maxObserves: lockedFromMaxSessions.observes,
				singleSession: lockedFromSingleSchedule
			}
		},
		hasBalanceDue(state, getters) {
			if (!getters?.balanceDueMap || !getters?.selectedUser || !getters?.selectedUserDrivingStudentCourseID) { return false; }

			const currentUserBalance = getters.balanceDueMap[getters.selectedUserDrivingStudentCourseID];

			return currentUserBalance < 0;
		},
		hasCustomDomain() {
			const isDriveTrakDomain = [
				'localhost',
				'mobile-qa.drivetrak.io',
				'mobile-uat.drivetrak.io',
				'mobile.drivetrak.io'
			].includes(window.location.hostname);

			return !isDriveTrakDomain;
		},
		hasMinimumVersion(state, getters) {
			let minimumVersion = '';
			if (getters.appPlatform === 'ios') {
				minimumVersion = getters?.featureFlags?.appVersions?.iOS?.min ?? '0.0.0';
			} else if (getters.appPlatform === 'android') {
				minimumVersion = getters?.featureFlags?.appVersions?.android?.min ?? '0.0.0';
			} else {
				return true;
			}

			return !getters.versionComparison({
				currentVersion: getters.appVersion,
				targetVersion: minimumVersion
			});
		},
		hasUpdateAvailable(state, getters) {
			let recentVersion = '';
			if (getters.appPlatform === 'ios') {
				recentVersion = getters?.featureFlags?.appVersions?.iOS?.current ?? '0.0.0';
			} else if (getters.appPlatform === 'android') {
				recentVersion = getters?.featureFlags?.appVersions?.android?.current ?? '0.0.0';
			} else {
				return false;
			}

			return getters.versionComparison({
				currentVersion: getters.appVersion,
				targetVersion: recentVersion
			});
		},
		initialized(state) {
			return state.initialized;
		},
		institutionSettings(state) {
			return state.institutionSettings;
		},
		stateSettings(state) {
			return state.stateSettings;
		},
		featureFlags(state) {
			return state.featureFlags;
		},
		onHoldMessage(state) {
			return state.onHoldMessage;
		},
		btwCourseOnHold(state) {
			return state.btwCourseOnHold;
		},
		eLearningCourseOnHold(state) {
			return state.eLearningCourseOnHold;
		},
		hasBTWCourse(state) {
			return state.hasBTWCourse;
		},
		observeDisabled(state) {
			return state.institutionSettings?.stateSetting?.schedulerSettings?.observationDisabled;
		},
		haseLearningCourse(state) {
			return state.haseLearningCourse;
		},
		hasCourse(state) {
			return state.hasCourse;
		},
		courseIsActive(state) {
			return state.courseIsActive;
		},
		courseComplete(state) {
			return state.courseComplete;
		},
		balanceDueMap(state) {
			return state.balanceDueMap;
		},
		notifications(state, getters) {
			const today = Date.now();
			return (state.notifications||[]).filter(n => (n.endDate > today) && (n.startDate <= today)).sort((a, b) => (b.startDate - a.startDate));
		},
		blockingSchedulerInfo(state) {
			return state.blockingSchedulerInfo;
		},
		selectedStudent(state, getters) {
			const student = state.students.find(s => s.userID === getters.selectedUser);
			return student;
		},
		selectedSessions(state) {
			return state.selectedSessions;	
		},
		maxObservesReached(state, getters) {
			const scheduledObserves = getters.selectedStudent?.observeHoursScheduled || 0;
			const completedObserves = getters.selectedStudent?.observeHours || 0;
			const requiredObserves = getters.selectedStudent?.requiredObserveHours || 0;
			const currentSelectedObserves = Object.values(getters.selectedSessions || {}).filter(({sessionType}) => sessionType === 'observe')?.length || 0;

			if ((scheduledObserves + completedObserves + currentSelectedObserves) >= requiredObserves) return true;
			return false;
		},
		maxDrivesReached(state, getters) {
			const scheduledDrives = getters.selectedStudent?.driveHoursScheduled || 0;
			const completedDrives = getters.selectedStudent?.driveHours || 0;
			const requiredDrives = getters.selectedStudent?.requiredDriveHours || 0;
			const currentSelectedDrives = Object.values(getters.selectedSessions || {}).filter(({sessionType}) => sessionType === 'drive')?.length || 0;

			if ((scheduledDrives + completedDrives + currentSelectedDrives) >= requiredDrives) return true;
			return false;
		},
		students(state) {
			state.students.forEach((student) => {
				const behindTheWheelStudentCourse = student.courses?.find(crs => ['registered', 'completed'].includes(crs.studentCourseStatus) && crs.course?.categories?.find(cat => cat.slug === 'behind-the-wheel'));
				student.driveHours = behindTheWheelStudentCourse?.driveHoursCompleted ?? 0;
				student.driveHoursScheduled = behindTheWheelStudentCourse?.driveHoursScheduled ?? 0;
				student.requiredDriveHours = behindTheWheelStudentCourse?.course.requiredDriveHours ?? 0;
				student.allowDriveOverage = behindTheWheelStudentCourse?.course.allowDriveOverage ?? false;
				student.requiredObserveHours = behindTheWheelStudentCourse?.course.requiredObserveHours ?? 0;
				student.allowObserveOverage = behindTheWheelStudentCourse?.course.allowObserveOverage ?? false;
				student.observeHours = behindTheWheelStudentCourse?.observationHoursCompleted ?? 0;
				student.observeHoursScheduled = behindTheWheelStudentCourse?.observationHoursScheduled ?? 0;
				student.earliestDriversTestDate = formatEarliestDriversTestDate(behindTheWheelStudentCourse?.earliestDriversTestDate);
			})
			return state.students;
		},
		eLearningMinimumFinishDate(state, getters) {
			/**
			 * Checks if the user is within the finishing day minimum. If not in the minimum it will
			 * return false otherwise it will return the minimum date.
			 */
			const student = state.students.find(s => s.userID === getters.selectedUser);
			const selectedUserClassroomStudentCourseID = cloneDeep(getters.selectedUserClassroomStudentCourseID);
			if (!selectedUserClassroomStudentCourseID) return false;

			const selectedUserClassroomStudentCourseIDSplit = selectedUserClassroomStudentCourseID.split('::');
			const studentCourseID = selectedUserClassroomStudentCourseIDSplit[2];

			if (student) {
				const studentCourse = student.courses.find(c => c.studentCourseID === studentCourseID);
				if (studentCourse.dates?.eLearningStart) {
					const today = startOfToday();
					const minDaysBeforeFinishing = addDays(parseISO(studentCourse.dates.eLearningStart), studentCourse.course.eLearningMinDaysBeforeFinishing);

					// If minimum finishing days later from eLearning start is earlier than today)
					if (minDaysBeforeFinishing > today) {

						// Return the minimum required date.
						return minDaysBeforeFinishing.getTime();
					}
				}
			}

			// Otherwise return false.
			return false;
		},
		timeSlotsData(state) {
			return state.timeSlotsData;
		},
		userAppointmentsData(state) {
			return state.userAppointmentsData;
		},
		selectedInstructorDrivingIDs(state) {
			return state.selectedInstructorDrivingIDs;
		},
		timeSlotVisibility(state) {
			return state.timeSlotVisibility
		},
		studentIDs(state) {
			const studentIDs = state.students.reduce((agg, curr) => { agg.push(curr.userID); return agg; }, []);
			return studentIDs;
		},
		selectedUser(state) {
			return state.selectedUser;
		},
		validSchedulerUser(state) {
			return state.validSchedulerUser;
		},
		selectedStudentClassroomInstances(state, getters) {
			return getters.selectedStudent?.courses?.flatMap(({course, studentCourseStatus}) => {
				if (!['pending', 'registered', 'completed'].includes(studentCourseStatus)) return [];
				return course.instances?.map(({start, end}) => ({
					start: new Date(start),
					end: new Date(end)
				})) || []
			}) || []
		},
		/**
		 *
		 * @param {string} args.topicID
		 * @param {string} args.lessonID
		 * @returns
		 */
		getNextStepUrl: (state, getters) => (args) => {
			let syllabus = getters.selectedSyllabus;
			let lesson = syllabus.lessons?.find((l) => l.lessonID === args.lessonID);

			if (args.topicID) {
				const topicIndex = lesson.topics.findIndex((t) => t.topicID === args.topicID);

				if (topicIndex === -1) {
					throw new Error('Missing topic in syllabus lesson');
				}

				if (topicIndex === lesson.topics.length - 1) {
					if (lesson.hasTest) {
						return {
							label: 'Continue to Lesson Test',
							url: `/quiztest/${args.lessonID}`
						}
					} else {
						return {
							label: 'Continue to Lesson Summary',
							url: `/summary/${args.lessonID}`
						}
					}
				} else {
					const nextTopicID = lesson.topics[topicIndex+1].topicID
					return {
						label: 'Continue to Next Topic',
						url: `/lessons/${args.lessonID}/${nextTopicID}`
					}
				}
			} else {
				const lessonIndex = syllabus.lessons.findIndex((l) => l.lessonID === args.lessonID);
				const nextLessonIndex = lessonIndex + 1;
				if (lessonIndex === -1) throw new Error('Missing lesson on syllabus');

				if (lessonIndex !== syllabus.lessons.length - 1) {
					if (syllabus.lessons[nextLessonIndex].inPerson) {
						return {
							label: 'Return Home',
							url: '/lessons'
						}
					}

					if (syllabus.lessons[nextLessonIndex].topics.length) {
						return {
							label: 'Continue to Next Lesson',
							url: `/lessons/${syllabus.lessons[nextLessonIndex].lessonID}/${syllabus.lessons[nextLessonIndex].topics[0].topicID}`
						}
					} else {
						if (syllabus.lessons[nextLessonIndex].hasTest) {
							return {
								label: 'Continue to Next Lesson Test',
								url: `/quiztest/${syllabus.lessons[nextLessonIndex].lessonID}`
							}
						} else {
							return {
								label: 'Continue to Next',
								url: `/summary/${syllabus.lessons[nextLessonIndex].lessonID}`
							}
						}
					}
				} else {
					return {
						label: 'Complete Final Lesson',
						url: `/lessons`
					}
				}
			}
		},
		/**
		 * @param {string} args.topicID
		 * @param {string} args.lessonID
		 * @returns {number}
		 */
		passingScore: (state, getters) => (args) => {

			if (!args) { return 0.8; }

			const syllabus = getters.selectedSyllabus;
			let passingScore = 0;

			// Get the syllabus passing score if it exists.
			if (args.topicID && syllabus.quizSettings?.requiredPassingScore) {
				passingScore = syllabus.quizSettings.requiredPassingScore;
			} else if (args.lessonID && syllabus?.testSettings?.requiredPassingScore) {
				passingScore = syllabus.testSettings.requiredPassingScore;
			}

			return ((passingScore) ? passingScore : 80) / 100;
		},
		/**
		 * @param {string} args.topicID
		 * @param {string} args.lessonID
		 * @param {string} args.progressItemID
		 * @returns {number}
		 */
		getQuizTestAttempts: (state, getters) => (args) => {

			// Get our target of either Quiz or Test by checking for the topicID.
			const referenceID = args.topicID ? args.topicID : args.lessonID;

			// Check that we have classroom progress otherwise default to 0.
			if (!getters.selectedClassroomProgress?.lessons) return 0;

			const lessonProgress = getters.selectedClassroomProgress.lessons.find((l) => l.lessonID === args.lessonID) ?? {};

			// If we don't have lesson progress return 0.
			if (!lessonProgress.progressItems) { return 0; }
			lessonProgress.progressItems.sort((a,b) => a.completedDate - b.completedDate);

			let progressItemsSearch = lessonProgress.progressItems;

			// If present, only consider attempts up to the given progress item.  Used for attempts counting on the Results page
			if (args.progressItemID) {
				const indexOfProgressItem = progressItemsSearch.findIndex((pi) => pi.id === args.progressItemID);

				if (indexOfProgressItem !== -1) {
					progressItemsSearch = progressItemsSearch.slice(0, indexOfProgressItem);
				}
			}

			const latestRetryMarker = progressItemsSearch.findLastIndex((pi) => pi.type === 'RETRY' && (pi.reference === referenceID || pi.reference === args.lessonID));

			let progressItemsAfterRetry = progressItemsSearch.slice(latestRetryMarker === -1 ? 0 : latestRetryMarker);

			const attempts = progressItemsAfterRetry.filter((pi) => pi.type === (args.topicID ? 'QUIZ' : 'TEST') && pi.reference === referenceID).length;
			return attempts;
		},
		studentsClassroomProgress(state) {
			return state.studentsClassroomProgress;
		},
		selectedUserClassroomStudentCourseID(state) {
			return state.selectedUserClassroomStudentCourseID
		},
		selectedUserDrivingStudentCourseID(state) {
			return state.selectedUserDrivingStudentCourseID;
		},
		selectedClassroomProgress(state) {
			return state.studentsClassroomProgress.find(scp => scp.userID === state.selectedUser)?.classroomUserProgress;
		},
		selectedSyllabus(state) {
			return state.studentsClassroomProgress.find(scp => scp.userID === state.selectedUser)?.classroomUserProgress?.syllabus;
		},
		hideTabFooter(state) {
			return state.hideTabFooter;
		},
		isDevMode(state) {
			return state.institutionSettings?.trial
				|| ['dev', 'development', 'qa'].includes(VUE_APP_ENVIRONMENT);
		},
		eLearningExtensionNeeded(state, getters) {
			return getters.selectedSyllabus?.extensionSettings?.enabled
				&& !getters.selectedClassroomProgress.completedDate
				&& getters.selectedClassroomProgress.lockDate
				&& getters.selectedClassroomProgress.lockDate < Date.now()
		},
		eLearningHasBeenExtended(state, getters) {
			return getters.selectedSyllabus?.extensionSettings?.enabled
				&& !getters.selectedClassroomProgress.completedDate
				&& getters.selectedClassroomProgress.isExtended
		},
		showeLearningExtensionWarning(state, getters) {
			return getters.selectedSyllabus?.extensionSettings?.enabled
				&& !getters.selectedClassroomProgress.completedDate
				&& getters.selectedClassroomProgress.lockDate
				&& getters.eLearningDaysFromLockDate >0
				&& getters.eLearningDaysFromLockDate <= getters.selectedSyllabus?.extensionSettings?.eLearningExtensionUpcoming

		},
		eLearningDaysFromLockDate(state, getters) {
			return differenceInCalendarDays(getters.selectedClassroomProgress.lockDate, Date.now())
		},
		/**
		 * Check if currentVersion is lower than targetVersion and return the results.
		 * @param state
		 * @param getters
		 * @param {Object} args
		 * @param {string} args.currentVersion
		 * @param {string} args.targetVersion
		 * @returns
		 */
		versionComparison: (state, getters) => (args) => {
			const cv = args.currentVersion.split('.').map(Number);
			const tv = args.targetVersion.split('.').map(Number);

			for (let i = 0; i < tv.length; i++) {
				if (cv[i] < tv[i]) return true;
				if (cv[i] > tv[i]) return false;
			}

			return false;
		},
		/**
		 * If this browser has the feature set we need for the app to function properly
		 */
		isCompatibleBrowser: (state) => {
			const {minimumSupportedMethods} = state.browserSupport;
			if (!minimumSupportedMethods) return true;
			if (minimumSupportedMethods.some(m => {
				switch (m.constructorType) {
					case 'Array':
						return !Array.prototype[m.name];
					case 'Object':
						return !Object.prototype[m.name];
					case 'Boolean':
						return !Boolean.prototype[m.name];
					case 'Number':
						return !Number.prototype[m.name];
					case 'String':
						return !String.prototype[m.name];
					case 'Symbol':
						return !Symbol.prototype[m.name];
					case 'BigInt':
						return !BigInt.prototype[m.name];
					default:
						return false;
				}
		})) return false;


			return true;
		},
		getAdditionalLogData() {
			var userAgentDetails = navigator.userAgent.toLowerCase();
			const platforms = getPlatforms();

			const logData = {
				browserName: platform.name || "Unknown",
				browserVersion: platform.version || "N/A",
				operatingSystem: "Unknown",
				isMobile: platforms.includes("mobile"),
				userAgent: platform.ua
			};

			// Determine operating system
			const osMappings = [
				{ keyword: "windows", regex: /windows nt ([0-9.]+)/, format: "Windows $1" },
				{ keyword: "mac", regex: /mac os x ([0-9._]+)/, format: "macOS $1" },
				{ keyword: "linux", regex: /linux x ([0-9._]+)/, format: "Linex $1" },
				{ keyword: "android", regex: /android x ([0-9._]+)/, format: "android $1" },
				{ keyword: "iphone", regex: /iphone x ([0-9._]+)/, format: "iOS $1" },
				{ keyword: "ipad", regex: /ipad x ([0-9._]+)/, format: "iOS $1" }
			];

			for (const mapping of osMappings) {
				if (userAgentDetails.includes(mapping.keyword)) {
					const versionMatch = mapping.regex.exec(userAgentDetails);
					logData.operatingSystem = versionMatch ? mapping.format.replace(/\$1/g, versionMatch[1].replace(/[^a-zA-Z0-9.]+/g, ".")) : `${mapping.format.split(" ")[0]} (version unknown)`;
					break;
				}
			}

			return logData;
		},
		enableFaro() {
			return ['uat', 'production'].includes(VUE_APP_ENVIRONMENT);
		},
		enableHotJar(state, getters) {
			try {
				const liteInstitutionWithFewerThanXCompletedLessons = () => {
					//checks for full institution
					if(getters.institutionSettings.moduleSettings.registration) return false
					//gets student course or false
					const selectedUserCourse = getters.studentsClassroomProgress.find(scp => scp.userID === getters.selectedUser)?.classroomUserProgress || false;
					//returns true for trouble shooting lite institution with no student course
					if(!selectedUserCourse) return true
					//Get the lesson limit for starting recordings at
					const maximumLiteLesson = getters.featureFlags?.logRocketSettings?.maximumLiteLesson || 1;
					//otherwise returns if student has no more than 5 lessons completed
					return (selectedUserCourse?.lessons?.filter(l => l.completeDate).length || 0) < maximumLiteLesson;
				}
				//returns a boolean based on if the institution slug isn't set or the institution has registration access and the role of parent or student
				const recordLog = (
					getters.institutionSettings.slug === ""
						||
					(
						(getters.user.isStudent
						|| getters.user.isParent)
							&&
						(getters.institutionSettings.moduleSettings.registration
						|| liteInstitutionWithFewerThanXCompletedLessons())
					)
				);

				if (!recordLog) return null;

				if (![
					'production'
				].includes(VUE_APP_ENVIRONMENT)) return null;

				return {
					siteID: 3690768,
					hotjarVersion: 6
				}
			} catch (e) {
				console.error('Hotjar settings error: ', e);
				return null;
			}
		}
	},
	actions: {
		async initialize({ getters, dispatch, commit }, data) {
			dispatch('setNetworkListener');
			dispatch('subscribeToInstitutionSettingsUpdate');
			await Promise.all([
				await dispatch('getInstitutionSettings'),
				await dispatch('getStateSettings'),
				await dispatch('getFeatureFlags'),
				await dispatch('getBrowserSupport'),
				await dispatch('getNotifications')
			]);
			// Depends on moduleAccess settings fetched above
			if(store.getters.user.isInstructor || store.getters.user.isAdmin || store.getters.user.isOwner) store.dispatch('hideTabFooter', true);

			if(store.getters.user?.userID && !store.getters.user?.isInstructor)
				await dispatch('getStudents', getters.user.userID)

			if (store.getters.enableHotJar) {
				const {
					siteID,
					hotjarVersion
				} = store.getters.enableHotJar;

				Hotjar.init(siteID, hotjarVersion);

				Hotjar.identify(getters.user.userID, {
					'name': `${getters.user.firstName} ${getters.user.lastName}`,
					'email': getters.user.email,
					'institution': getters.user.institutions[0],
					'version': APP_VERSION
				});

				// Add data-hj-allow attribute to all <input> elements
				// The attribute needs to be applied directly to the input elements,
				// Vuetify doesn't expose those, so it must be done manually upon DOM mutation
				function addDataAllowAttribute(node) {
					if (node.tagName === 'INPUT') {
						node.setAttribute('data-hj-allow', '')
					} else if (node.children && node.children.length) {
						for (const child of node.children) {
							addDataAllowAttribute(child)
						}
					}
				}

				new MutationObserver((mutationList, observer) => {
					for (const mutation of mutationList) {
						if (mutation.type === 'childList') {
							mutation.addedNodes.forEach((node) => addDataAllowAttribute(node))
						}
					}
				}).observe(document.body, {
					childList: true,
					subtree: true
				})
			}
			// if (VUE_APP_ENVIRONMENT === 'production' && recordLog) {
			// 	LogRocket.init('thqz4i/drivetrak-app', {
			// 		network: {
			// 			requestSanitizer: request => {
			// 				// ignore login route
			// 				if (request.url.toLocaleLowerCase().indexOf('login') !== -1 ) {
			// 					return null;
			// 				}
			// 				// remove authorization header
			// 				const authKey = Object.keys(request.headers).find(hKey => hKey.toLowerCase() === 'authorization');
			// 				if (authKey) {
			// 					request.headers[authKey] = '';
			// 				}
			// 				return request;
			// 			}
			// 		},
			// 		mergeIframes: true
			// 	});

			// 	const originalConsoleError = console.error;
			// 	console.error = function (...e) {
			// 		if (e instanceof Error) {
			// 			LogRocket.captureException(e[0]);
			// 		} else {
			// 			const isString = typeof(e[0]) === 'string';
			// 			LogRocket.captureMessage(isString ? e[0] : JSON.stringify(e));
			// 		}
			// 		originalConsoleError(...e);
			// 	}
			// }

			// 	LogRocket.identify(state.user.userID, {
			// 		name: `${state.user.firstName} ${state.user.lastName}`,
			// 		email: state.user.email,
			// 		institution: state.user.institutions[0],
			// 		version: APP_VERSION,
			// 	});

			store.dispatch('setupOfflineListener');
			await store.dispatch('setupSyncData');

			commit('initialize');
		},
		async logOut({ state, dispatch }){
			state.selectedUser = '';
			state.validSchedulerUser = false;
			state.selectedUserClassroomStudentCourseID = null;
			state.recalculateBalance = false;
			state.institutionSettings = {
				email: '',
				moduleSettings: {
					schedulerAccess: false,
					eLearning: false,
					registration: false,
					instructorApp: false
				},
				name: '',
				phone: '',
				instructorAppSettings: null,
				schedulerSettings: {
					cancellationFee: 0,
					mandatoryGap: 0,
					calculateMandatoryGap: false,
					calculateMandatoryGapOffsetDays: 0,
					minimumCancellationTime: '',
					hideInstructors: false,
					lockFutureAppointments: null,
					schedulingOverrides: null
				},
				slug: '',
				notifications: [],
				blockingSchedulerInfo: {
					formattedMessage: '',
					minimumPaymentAmount: 0,
					behindTheWheelStudentCourseTotals: null,
				},
				paymentSettings: null,
				registrationInstitutionConfig: null,
				stateSetting: null,
				timeZone: 'America/Chicago',
				trial: false
			};
			state.students = [];
			state.onHoldMessage = '';
			state.btwCourseOnHold = false;
			state.eLearningCourseOnHold = false;
			state.hasBTWCourse = false;
			state.haseLearningCourse = false;
			state.hasCourse = false;
			state.courseIsActive = false;
			state.balanceDueMap = {};
			state.courseComplete = true;
			state.initialized = false;

			dispatch('clearSyncData');
			Preferences.clear();
		},
		async getClassroomUserProgress({ commit, state, getters}) {
			try {
				if (!getters.selectedUserClassroomStudentCourseID || !getters.institutionSettings.moduleSettings.eLearning) return;

				const classroomUserProgress = await api.getClassroomUserProgress(getters.selectedUserClassroomStudentCourseID);

				if (classroomUserProgress.syllabus.lessons.some((l) => !l.inPerson)) {
					if (!classroomUserProgress.syllabus.quizSettings) console.error("Missing necessary quiz settings on syllabus.  The app may not behave correctly.")
					if (!classroomUserProgress.syllabus.testSettings) console.error("Missing necessary test settings on syllabus.  The app may not behave correctly.")
				}

				if (classroomUserProgress) {
					commit('setStudentsClassroomProgress', {
						userID: getters.selectedUser,
						classroomUserProgress: classroomUserProgress
					});

					if (classroomUserProgress.syllabus.extensionSettings?.enabled) {
						const eLearningExtensionSub = eLearningExtendedSubscription({studentCourseID: (getters.selectedUserClassroomStudentCourseID.split('::')?.[2] || '')});

						eLearningExtensionSub.onResult(({data}) => {
							const progressIndex = state.studentsClassroomProgress.findIndex(cup => cup.userID === state.selectedUser);
							if (progressIndex === -1) {
								throw new Error("Missing classroom user progress")
							} else {
								state.studentsClassroomProgress[progressIndex].classroomUserProgress.lockDate = data.eLearningExtended;
							}

							toast.success({
								message: 'eLearning has been Extended',
								duration: 5000,
								position: 'top'
							});
						})
					}
				}
			} catch (e) {
				console.error(e)
				toast.error({
					message: 'Unable to get classroom user progress',
					duration: 3000,
					position: 'top'
				})
			}
		},
		async checkCustomHostname() {
			const hostname = window.location.hostname;

			if([
				'dashboard-qa.crashcoursewi.com',
				'dashboard-uat.crashcoursewi.com',
				'dashboard.crashcoursewi.com'
			].includes(hostname)) {
				store.dispatch('getInstitutionSettings', 'crash-course');
			}
		},
		async switchSelectedUser({ commit, state, dispatch }, activeUserID) {
			commit('setSelectedUser', activeUserID);

			const oCurrentSelectedUser = state.students.find(st => st.userID === state.selectedUser);
			await Promise.all([
				// Fetches data from API
				dispatch('prepareUserCourses', oCurrentSelectedUser),
				// Set flags based on the selected user's courses
				dispatch('checkOnHold', oCurrentSelectedUser),
				dispatch('checkStudentHasCourse', oCurrentSelectedUser),
				dispatch('checkCourseIsActive', oCurrentSelectedUser),
				dispatch('checkBalanceDue', oCurrentSelectedUser)
			])

			commit('setValidSchedulerUser', !(!oCurrentSelectedUser.permitNumber || !oCurrentSelectedUser.permitIssued || !oCurrentSelectedUser.permitIssuingState));
		},
		async fetchLessonQuestions({ commit, state, getters }, {lessonID, attemptNum}) {
			try {
				const classroomUserProgress = getters.selectedClassroomProgress;

				const lessonIdx = classroomUserProgress.syllabus.lessons.findIndex((l) => l.lessonID === lessonID)

				if (lessonIdx === -1 ) throw new Error("Missing lessonID from syllabus")

				if (!classroomUserProgress.syllabus.lessons[lessonIdx].test || !classroomUserProgress.syllabus.testSettings.allowQuestionReuse) {
					const syllabusID = `syllabus::${classroomUserProgress.syllabus.id}::${classroomUserProgress.syllabus.version}`;
					const lessonWithQuestions = await api.getLessonWithQuestions(lessonID, syllabusID, attemptNum);

					const newProgress = cloneDeep(classroomUserProgress)

					newProgress.syllabus.lessons[lessonIdx] = lessonWithQuestions;
					commit('setStudentsClassroomProgress', {
						userID: getters.selectedUser,
						classroomUserProgress: newProgress
					});

					return lessonWithQuestions;
				} else {
					return classroomUserProgress.syllabus.lessons[lessonIdx];
				}


			} catch (e) {
				console.error(e)
				toast.error({
					message: 'Unable to get lesson data',
					duration: 3000,
					position: 'top'
				})
			}
		},
		async getDailyELearningLimit({ commit, state, getters }, userID: string) {
			try {
				const response = await api.getDailyELearningLimit(userID);
				commit('setDailyELearningLimitMet', response);
			} catch (e) {
				console.error(e)
			}
		},
		async submitClassroomProgress({ commit, state, getters }, progressInputs) {
			try {
				const response = await api.submitClassroomProgressions(getters.selectedUserClassroomStudentCourseID, progressInputs);
				if (response instanceof DailyLimitMetError) {
					commit('setDailyELearningLimitMet', response.message);
					return response;
				}

				const classroomUserProgress = cloneDeep(getters.selectedClassroomProgress);

				for (const lesson of response.lessons) {
					const progressIndex = classroomUserProgress.lessons.findIndex((l) => l.lessonID === lesson.lessonID);

					if (progressIndex === -1) {
						classroomUserProgress.lessons.push(lesson);
					} else {
						classroomUserProgress.lessons[progressIndex] = lesson;
					}
				}

				for (const timeSpent of response.timeSpent) {
					const timeSpentIndex = classroomUserProgress.timeSpent.findIndex(({date}) => date === timeSpent.date);

					if (timeSpentIndex === -1) {
						classroomUserProgress.timeSpent.push(timeSpent);
					} else {
						classroomUserProgress.timeSpent[timeSpentIndex] = timeSpent;
					}
				}

				if (response.completedDate) {
					classroomUserProgress.completedDate = response.completedDate;
				}

				commit('setStudentsClassroomProgress', {
					userID: getters.selectedUser,
					classroomUserProgress
				});

				return response;
			} catch (e) {
				console.error(e);
				toast.error({
					message: 'Unable to submit classroom progress',
					duration: 3000,
					position: 'top'
				})
			}
		},
		async prepareUserCourses({commit, dispatch}, oCurrentSelectedUser) {
			const currentSelectedUserClassroomStudentCourse = oCurrentSelectedUser.courses.find(stCourse => {
				return stCourse.course.categories.find((cat: CourseCategory) => cat.slug === 'e-learning') &&
				stCourse?.classroomUserProgress?.syllabus &&
				['registered', 'completed'].includes(stCourse?.studentCourseStatus)
			});
			const currentSelectedUserDrivingStudentCourse = oCurrentSelectedUser.courses.find(stCourse => {
				return stCourse.course.categories.find((cat: CourseCategory) => cat.slug === 'behind-the-wheel') &&
				['registered', 'completed'].includes(stCourse?.studentCourseStatus)
			});
			if (currentSelectedUserClassroomStudentCourse) {
				commit('setSelectedUserClassroomStudentCourseID', `studentCourse::${oCurrentSelectedUser.institution}::${currentSelectedUserClassroomStudentCourse.studentCourseID}`);
			}
			if (currentSelectedUserDrivingStudentCourse) {
				commit('setSelectedUserDrivingStudentCourseID', currentSelectedUserDrivingStudentCourse.studentCourseID);
			}
			await dispatch('getClassroomUserProgress')
		},
		checkOnHold({commit}, oCurrentSelectedUser) {
			if(oCurrentSelectedUser.courses.find(stCourse => stCourse.course.categories.find(cat => cat.slug === 'behind-the-wheel' && stCourse.onHold))) {
				commit('setBTWCourseOnHold', true);
			} else commit('setBTWCourseOnHold', false);

			if(oCurrentSelectedUser.courses.find(stCourse => stCourse.course.categories.find(cat => cat.slug === 'e-learning' && stCourse.onHold))) {
				commit('seteLearningCourseOnHold', true);
			} else commit('seteLearningCourseOnHold', false);
		},
		checkStudentHasCourse({commit}, oCurrentSelectedUser) {
			const activeCourses = oCurrentSelectedUser.courses.filter(stCourse => ['registered', 'completed'].includes(stCourse.studentCourseStatus));
			if(activeCourses.length > 0) {
				commit('setHasCourse', true);
				const allCoursesComplete = activeCourses.every(c => c.studentCourseStatus === 'completed')
				commit('setCourseComplete', allCoursesComplete)
				if(activeCourses.find(stCourse => (stCourse.course.categories.find(cat => cat.slug === 'behind-the-wheel')))) {
					commit('setHasBTWCourse', true);
				} else commit('setHasBTWCourse', false);

				if(activeCourses.find(stCourse => (stCourse.course.categories.find(cat => cat.slug === 'e-learning')))) {
					commit('setHaseLearningCourse', true);
				} else commit('setHaseLearningCourse', false);
			} else {
				commit('setHasBTWCourse', false);
				commit('setHaseLearningCourse', false);
				commit('setHasCourse', false);
				commit('setCourseComplete', false);
			}
		},
		async checkCourseIsActive({commit}, oCurrentSelectedUser) {
			const everyCourseHasElearning = oCurrentSelectedUser.courses.every(stCourse => stCourse.course.categories.find(cat => cat.slug === 'e-learning'));

			//second check is to find WPDSA default course before course activation
			if(everyCourseHasElearning || oCurrentSelectedUser.courses.every(stCourse => stCourse.course.courseID === 'IS_WPDSA')) {
				const haseLearningActivationDate = Boolean(oCurrentSelectedUser.courses.find(stCourse => stCourse.dates.eLearningActivation));
				commit('setCourseIsActive', haseLearningActivationDate);
			} else commit('setCourseIsActive', true);

		},
		async setELearningStartDate({ commit, state, getters }) {
			try {
				const {eLearningStart} = await api.setELearningStartDate(getters.selectedUserClassroomStudentCourseID);
				const selectedUserClassroomStudentCourseIDSplit = getters.selectedUserClassroomStudentCourseID.split('::');
				const studentCourseID = selectedUserClassroomStudentCourseIDSplit[2];
				const student = state.students.find(s => s.userID === getters.selectedUser);
				const studentCourse = student.courses.find(c => c.studentCourseID === studentCourseID);
				studentCourse.dates.eLearningStart = eLearningStart;
			} catch (e) {
				console.error(e);
				toast.error({
					message: 'Unable to update eLearning start date',
					duration: 3000,
					position: 'top'
				})
			}
		},
		async checkBalanceDue ({commit, state}, student) {
			const userCoursesBalancesMap = {};

			if (!student?.courses) commit('setBalanceDueMap', userCoursesBalancesMap);
			student?.courses?.forEach(studentCourse => {
				userCoursesBalancesMap[studentCourse.studentCourseID] = studentCourse.balanceDue ?? 0;
			});

			commit('setBalanceDueMap', userCoursesBalancesMap);
		},
		async getStudents({ dispatch, commit, state, getters}, userID) {
			try {
				if (!getters.isAuthenticated) {
					return;
				}
				const user = await api.getUserDetails(userID);
				let students = [];
				if (user.roles.some((role) => role.slug === 'student')) {
					students = [user];
				} else {
					students = user.students.sort((a, b) => {
						const foundActiveCourseA = a.courses.find((stCourse: StudentCourse) => stCourse.studentCourseStatus === 'registered' && !stCourse.onHold);
						const foundActiveCourseB = b.courses.find((stCourse: StudentCourse) => stCourse.studentCourseStatus === 'registered' && !stCourse.onHold);

						if (foundActiveCourseA && !foundActiveCourseB) return -1;
						if (!foundActiveCourseA && foundActiveCourseB) return 1;
						return 0;
					});
				}
				if (!state.selectedUser) {
					commit('setSelectedUser', students?.[0]?.userID);
				}

				// assign permit validation to students
				for (const studentUser of students) {
					// Make sure the student has all their permit details before accessing.
					if(!studentUser.permitNumber || !studentUser.permitIssued || !studentUser.permitIssuingState) {
						studentUser.hasValidPermitInfo = false;
					} else {
						studentUser.hasValidPermitInfo = true;
					}
				}
				const oCurrentSelectedUser = students.find(st => st.userID === state.selectedUser);
				await Promise.all([
					// Fetches data from API
					dispatch('prepareUserCourses', oCurrentSelectedUser),
					// Set flags based on the selected user's courses
					dispatch('checkOnHold', oCurrentSelectedUser),
					dispatch('checkStudentHasCourse', oCurrentSelectedUser),
					dispatch('checkCourseIsActive', oCurrentSelectedUser),
					dispatch('checkBalanceDue', oCurrentSelectedUser)
				])

				commit('setValidSchedulerUser', oCurrentSelectedUser.hasValidPermitInfo);

				commit('setStudents', students);
			} catch(error) {
				console.error(error);
			}
		},
		async setMandatoryGapSpans({ state }, futureDrivingData) {
			const oCurrentSelectedUser = state.students.find(st => st.userID === state.selectedUser);
			const studentBTWCourse = oCurrentSelectedUser.courses.find(course => { return ( course.studentCourseStatus === 'registered' && course.course.categories.some(category => category.slug === 'behind-the-wheel')); });

			studentBTWCourse.futureDrivingData = futureDrivingData;
		},
		async refetchMandatoryGapSpans({ state }) {
			const oCurrentSelectedUser = state.students.find(st => st.userID === state.selectedUser);
			const studentBTWCourse = oCurrentSelectedUser.courses.find(course => { return ( course.studentCourseStatus === 'registered' && course.course.categories.some(category => category.slug === 'behind-the-wheel')); });

			studentBTWCourse.futureDrivingData = await api.getMandatoryGapSpans(state.institutionSettings.slug, studentBTWCourse.studentCourseID);
		},
		setAttemptedRoute({ commit }, attemptedRoute) {
			commit('setAttemptedRoute', attemptedRoute);
		},
		async setNetworkListener({ commit }) {
			async function updateConnectionStatus(incomingStatus) {
				if (incomingStatus) {
					commit('setConnectionStatus', incomingStatus);
					return;
				}
				const status = await Network.getStatus();

				commit('setConnectionStatus', status);
			}

			Network.addListener('networkStatusChange', updateConnectionStatus);
			// initial connection check
			await updateConnectionStatus(undefined);

			// connection polling every 30 seconds in case the listener lands on a bad state
			const timeOut = 30000;
			setInterval(() => {
				updateConnectionStatus(undefined);
			}, timeOut);
		},
		setInstitutionSlug({commit}, slug) {
			if (slug) {
				commit('setInstitutionSlug', slug);
			}
		},
		subscribeToInstitutionSettingsUpdate({ commit, state, getters, dispatch }) {
			try {
				const institutionSettingsSubObservable = api.subscribeToInstitutionSettings(getters.user.institutions[0]);
				institutionSettingsSubObservable.subscribe(() => {
					dispatch('getInstitutionSettings');
				});
			} catch (e) {
				console.error(e);
			}
		},
		async getInstitutionSettings({commit, state, getters}, slug) {
			try {
				let institutionSlug = '';

				if (slug) {
					institutionSlug = slug;
				} else if (getters?.user?.institutions?.length) {
					institutionSlug = getters.user.institutions[0];
				}

				if (institutionSlug !== '') {
					const institutionSettings = await api.getInstitutionSettings(institutionSlug);
					commit('setInstitutionSettings', institutionSettings);
					commit('setOnHoldMessage', `<p class="ion-margin-bottom">This course has been placed on administrative hold.</p>
					<p>Contact ${store.getters.institutionSettings.name} at <br />${store.getters.institutionSettings.phone}</p>`);
				}
			} catch(e) {
				console.error(e);
			}
		},
		async getStateSettings({commit, state, getters}) {
			try {
				const stateSettings = await api.getStateSettings();
				commit('setStateSettings', stateSettings);
			} catch(e) {
				console.error(e);
			}
		},
		async getNotifications({commit, state, getters}) {
			try {
				if (getters?.user?.institutions?.length) {
					const notifications = await api.getNotifications(getters.user.institutions[0]);
					commit('setNotifications', notifications);

					const notificationsUpdatedSub = notificationsUpdatedSubscription({institution: getters.user.institutions[0]});

					notificationsUpdatedSub.onResult(({data}) => {
						const notifications = data.notificationsUpdated;
						commit('setNotifications', notifications);
					})
				}
			} catch(e) {
				console.error(e);
			}
		},
		async getFeatureFlags({commit, state, getters}) {
			try {
				const featureFlags = await api.getFeatureFlags();
				commit('setFeatureFlags', featureFlags);
			} catch(e) {
				console.error(e);
			}
		},
		async getBrowserSupport({commit, state, getters}) {
			try {
				const browserSupport = await api.getBrowserSupport();
				commit('setBrowserSupport', browserSupport);
			} catch(e) {
				console.error(e);
			}
		},
		hideTabFooter({ commit }, hideTabFooter) {
			commit('setHideTabFooter', hideTabFooter);
		},
		async extendELearning({state, getters, dispatch}, txID) {
			const newLockDate = await api.extendELearning(getters.selectedUserClassroomStudentCourseID.split('::')[2], txID)

			const progressIndex = state.studentsClassroomProgress.findIndex(cup => cup.userID === state.selectedUser);
			if (progressIndex === -1) {
				throw new Error("Missing classroom user progress")
			} else {
				state.studentsClassroomProgress[progressIndex].classroomUserProgress.lockDate = newLockDate;
			}

			toast.success({
				message: 'eLearning Extended',
				duration: 5000,
				position: 'top'
			});

			if (txID === 'n/a') {
				dispatch('checkBalanceDue', state.students.find(s => s.userID === state.selectedUser));
			}
		},
		async logFaroEvent({state, getters}, {name, data}) {
			if (!getters.enableFaro) return;
			if (!name) return;
			if (!data) return;

			function makeAttributesFromVariables(res, prefix, value) {
				if (Array.isArray(value)) {
					if (value.length) {
						value.forEach((val, index) => makeAttributesFromVariables(res, `${prefix}[${index}]`, val))
					} else {
						res[prefix] = '[]';
					}
				} else if (value && typeof value === 'object') {
					for (const prop in value) {
						makeAttributesFromVariables(res, `${prefix}.${prop}`, value[prop])
					}
				} else if (value && ['string', 'boolean', 'number', 'bigint'].includes(typeof value)) {
					res[prefix] = String(value)
				}

				return res;
			}

			faro?.api?.pushEvent(name, makeAttributesFromVariables({}, name, data));
		}
	},
	mutations: {
		setFeatureFlags(state, featureFlags: FeatureFlags) {
			state.featureFlags = featureFlags;
		},
		setBrowserSupport(state: State, browserSupport: BrowserSupport) {
			state.browserSupport = browserSupport;
		},
		setConnectionStatus(state, status) {
			state.connectionStatus = status;
		},
		setStudentsClassroomProgress(state, args) {
			const progressIndex = state.studentsClassroomProgress.findIndex(cup => cup.userID === args.userID);
			if (progressIndex === -1) {
				state.studentsClassroomProgress.push(args);
			} else {
				state.studentsClassroomProgress[progressIndex].classroomUserProgress = args.classroomUserProgress;
			}
		},
		setDailyELearningLimitMet(state, dailyELearningLimit: number) {
			const today = formatInTimeZone(new Date, state.institutionSettings.timeZone, 'yyyy-MM-dd');
			if (!dailyELearningLimit) {
				state.dailyELearningLimits.delete(today);
			} else {
				state.dailyELearningLimits.set(today, {
					formatted: durationFormatterEn(dailyELearningLimit),
					dailyELearningLimit: dailyELearningLimit,
				});
			}
		},
		setHideTabFooter(state, hideTabFooter) {
			state.hideTabFooter = hideTabFooter;
		},
		setTimeSlotsData(state, timeslots: TimeSlot[]) {
			state.timeSlotsData = timeslots;
		},
		setUserAppointmentsData(state, timeslots: TimeSlot[]) {
			state.userAppointmentsData = timeslots;
		},
		setSelectedInstructorDrivingIDs(state, instructorDrivingIDs: InstructorDrivingIDs) {
			faro?.api?.pushEvent('setSelectedInstructorDrivingIDs', instructorDrivingIDs)
			state.selectedInstructorDrivingIDs = instructorDrivingIDs;
		},
		setTimeSlotVisibility(state, visibility) {
			state.timeSlotVisibility = visibility;
		},
		setStudents(state, students: User[]) {
			state.students = students;
		},
		initialize(state) {
			state.initialized = true;
		},
		setNetworkError(state, isError) {
			state.networkError = isError;
		},
		setAuthError(state, isError) {
			state.authError = isError;
		},
		setSelectedUser(state, userID: GUID) {
			state.selectedUser = userID;
		},
		setValidSchedulerUser(state, validSchedulerUser: boolean) {
			state.validSchedulerUser = validSchedulerUser;
		},
		setSelectedUserClassroomStudentCourseID(state, selectedUserClassroomStudentCourseID: string | null) {
			state.selectedUserClassroomStudentCourseID = selectedUserClassroomStudentCourseID;
		},
		setSelectedUserDrivingStudentCourseID(state, selectedUserDrivingStudentCourseID: string | null) {
			state.selectedUserDrivingStudentCourseID = selectedUserDrivingStudentCourseID;
		},
		setBTWCourseOnHold(state, onHold: boolean) {
			state.btwCourseOnHold = onHold;
		},
		seteLearningCourseOnHold(state, onHold: boolean) {
			state.eLearningCourseOnHold = onHold;
		},
		setHasBTWCourse(state, hasCourse: boolean) {
			state.hasBTWCourse = hasCourse;
		},
		setHaseLearningCourse(state, hasCourse: boolean) {
			state.haseLearningCourse = hasCourse;
		},
		setHasCourse(state, hasCourse: boolean) {
			state.hasCourse = hasCourse;
		},
		setCourseIsActive(state, hasActiveCourse: boolean) {
			state.courseIsActive = hasActiveCourse;
		},
		setCourseComplete(state, hasCompleteCourse: boolean) {
			state.courseComplete = hasCompleteCourse;
		},
		setBalanceDueMap(state, balanceDueMap : object) {
			state.balanceDueMap = balanceDueMap;
		},
		setAttemptedRoute(state, route: string) {
			state.attemptedRoute = route;
		},
		hideHelpModal(state, user) {
			state.showHelpModal = false;
		},
		hideConnectionModal(state, user) {
			state.showConnectionModal = false;
		},
		showHelpModal(state, user) {
			state.showHelpModal = true;
		},
		showConnectionModal(state, user) {
			state.showConnectionModal = true;
		},
		showSettingsModal(state, data) {
			state.showSettingsModal = true;

			// If set, opens the dialog to a specific settings view
			// If data.type === 'userDetails', opens to Permit Details.
			// - Expects data.user to be the userID to change
			// If data.type === 'billing', opens to Billing.
			// - Expects data.studentCourseID
			// - If data.requirePayment it set
			// --- Payment amount is locked to the value of data.paymentAmount
			// --- When payment is successful, the dialog is closed
			// --- and data.paymentCallback() is called
			state.showSettingsData = data;
		},
		hideSettingsModal(state) {
			state.showSettingsModal = false;
		},
		setInstitutionSettings(state, institutionSettings: InstitutionSettings) {
			state.institutionSettings = institutionSettings;
		},
		setInstitutionSlug(state, slug: string) {
			state.institutionSettings.slug = slug;
		},
		setStateSettings(state, stateSettings: StateSettings[]) {
			state.stateSettings = stateSettings;
		},
		setNotifications(state, notifications: Notification[]) {
			state.notifications = notifications;
		},
		setBlockingSchedulerInfo(state, blockingSchedulerInfo: BlockingSchedulerInfo) {
			state.blockingSchedulerInfo = blockingSchedulerInfo;
		},
		setOnHoldMessage(state, message: string) {
			state.onHoldMessage = message;
		},
		setSelectedSessions(state, selectedSessions: SelectedSessions | null) {
			state.selectedSessions = selectedSessions;
		}
	}
});

export { store };